import React, {useState} from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Carousel from "../components/carousel"
import ImageRow from "../components/image-row"
import Break from "../components/break"
import Titles from "../components/titles"
import SEO from "../components/seo"
import Showcase from "../components/showcase"



var showcaseActive = false;
const artist_rows = (artists, currentArtist, setCurrentArtist) => {

  let artist_rows = artists.map((artist) => <h3 className={currentArtist == artist.title ? "active" : null} onClick={() => {setCurrentArtist(artist.title)}}>{artist.title}</h3> )
  
  
  
  
  return artist_rows
}

const artwork_row = (artworks, currentArtist) => {
      let artwork_rows = artworks.filter((artwork) => {
        if(currentArtist) {
          if(artwork.artist[0].title == currentArtist) {
            return true
          }
          return false
        }
        return true;
      } ).sort((entry,entryb) => {
      if(entry.title.substr(-6) < entryb.title.substr(-6)){
        return -1
      }  else if(entry.title.substr(-6) > entryb.title.substr(-6)) {
        return 1
      }
    return 0}).reduce((resultArray, item, index) => { 
    const chunkIndex = Math.floor(index/3)

    if(!resultArray[chunkIndex]) {
    resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push({
    type:"image", 
    link:"/" + item.slug,
    url:item.images?.[0]?.url,
    details:<div><div className="title">{item.artist[0].title}</div><div className="materials">{item.title}</div><div className="dimensions">{item.materials}
    </div></div>
    })

    return resultArray
    }, []).map((row) => <ImageRow images={row} /> )
    
    return artwork_rows;
}

const IndexPage = ({pageContext}) => {

  const [currentArtist, setCurrentArtist] = useState(undefined);
  
  if(pageContext.exhibition) {
  return (<div>
  <Layout hero={<Carousel images={[pageContext.exhibition.images.map(image => image.url)]} />}>
    <SEO title={pageContext.exhibition.title} />

    <div style={{ }}>
      <h1 style={{marginBottom:"60px", fontSize:"36px",marginTop:"80px", textAlign:"center"}}>{pageContext.exhibition.title}</h1>
<div className={"titles inline"}><h3>Download catalogue</h3></div>  
      <ImageRow rowType={"largeRow"} images={[{type:"text", text: <div><p style={{padding:"36px 20px", fontSize:"18px", whiteSpace: "pre-wrap" }}>{pageContext.exhibition.location}<br />20th May 2021 - 24th June 2021<br /><br />{pageContext.exhibition.pageText}</p></div>, span:"12"}] } />


            <div className={"titles inline"}>   
        <h3 className={currentArtist == undefined ? "active" : null} onClick={() => {setCurrentArtist(undefined)}}>All works in this exhibition</h3> 
        {artist_rows(pageContext.exhibition.artist, currentArtist, setCurrentArtist)}
</div>
        
      
      {artwork_row(pageContext.exhibition.artworks, currentArtist)}


  </div>
  </Layout>
</div>)
}
return <div></div>
}

export default IndexPage
