import React, {useState} from "react"
import { Link } from "gatsby"
import Carousel from "./carousel"

const ShowCaseItem = ({image,text,index,current,nextImage}) => {
  
  let active = index == current;

  return <div style={{
    opacity: active? 1 : 0,
    position:"fixed",
    zIndex:1000,
    background:"black",
    top:0,left:0,bottom:0,right:0,
    backgroundImage: `url(${image})`
  }}
  onClick={() => {nextImage()}}>
    {text}
  </div>
}
function Showcase({active}) {
  
  const [showcaseIndex, setShowcaseIndex] = useState(0);
  let data = [{image:"/adam-imago.webp",text:<div>"IMAGO"</div>},{image:"/adam-imago.webp",text:<div>"LAGO"</div>}]
  function next() {
    let index = 0;
    let arr = [0,1,2,3];
    return function() {

    }
  }
  
  return <div style={{
    pointerEvents: active? "auto": "none",
    opacity: active? 1 : 0,
    position:"fixed",
    zIndex:1000,
    background:"black",
    top:0,left:0,bottom:0,right:0,
  }}
  >
    {data.map((item,index) => <ShowCaseItem 
      image={item.image} 
      text={item.text} 
      index={index} 
      nextImage={next}
      current={showcaseIndex} />)}
  </div>
  
}


Showcase.defaultProps = {

}

Showcase.propTypes = {

}

export default Showcase